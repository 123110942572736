import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Image,
  Nav,
  Navbar,
} from "@themesberg/react-bootstrap";
import { HashLink } from "react-router-hash-link";

import LogoPrimary from "../../../assets/img/landing/client/logo-primary.png";
import LogoLightBlack from "../../../assets/img/landing/client/logo-light1.png";
import { useHistory } from "react-router";
import { NAV_LINKS_CLIENT } from "../../../utils/constants";

const NavbarClient = () => {
  const history = useHistory();

  const [colorChange, setColorchange] = useState(false);

  let config = {
    logo: LogoPrimary,
    variant: "light",
    theme: "navbar-theme-primary",
    bg: "dark",
  };
  if (colorChange) {
    config = {
      logo: LogoLightBlack,
      variant: "light",
      theme: "navbar-theme-light",
      bg: "light",
    };
  }
  //   const logo = colorChange ? LogoLightBlack : LogoLight;

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      //   console.log(`colorChange >= 80`, colorChange);
      !colorChange && setColorchange(true);
    } else {
      //   console.log(`colorChange < 80`, colorChange);
      colorChange && setColorchange(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, [colorChange]);

  const [isExpanded, setisExpanded] = useState(false);

  return (
    <Navbar
      variant={config.variant}
      expand="lg"
      //   bg={config.variant}
      className={`navbar-transparent ${config.theme} sticky-top`}
      expanded={isExpanded}
      onToggle={(expanded) => setisExpanded(expanded)}
      onSelect={() => setisExpanded(false)}
    >
      <Container className="position-relative px-3">
        <Row className="w-100" style={{ flex: 1 }}>
          <Col xs={11} md={5} lg={3}>
            <Row>
              <Col xs={4}>
                <Navbar.Brand
                  as={HashLink}
                  to="/"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" })
                  }
                >
                  <Image src={config.logo} />
                </Navbar.Brand>
              </Col>
              <Col xs={8}>
                <div className={`d-flex switch ${config.bg}`}>
                  <div
                    className="switch-item switch-client ff-black selected"
                    onClick={() => history.replace("/")}
                  >
                    Klient
                  </div>
                  <div
                    className="switch-item switch-salon ff-black"
                    onClick={() => history.replace("/biznes")}
                  >
                    Biznes
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={7} lg={9}>
            {isExpanded && (
              <div
                id="overlay"
                className="position-absolute"
                style={{ width: "100vw", height: "100vh" }}
                onClick={() => setisExpanded(false)}
              />
            )}
            <Navbar.Collapse className={`custom-navbar justify-content-end`}>
              <Nav className="navbar-nav-hover align-items-lg-center">
                {NAV_LINKS_CLIENT.map((link) => (
                  <Nav.Link
                    key={link.href}
                    className="ff-black"
                    href={link.href}
                  >
                    {link.title}
                  </Nav.Link>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Col>
        </Row>
        <Navbar.Toggle aria-controls="navbar-default-primary" />
      </Container>
    </Navbar>
  );
};

export default NavbarClient;

import IMG1 from "../assets/img/landing/client/services/02.png";
import IMG2 from "../assets/img/landing/client/services/04.png";
import IMG3 from "../assets/img/landing/client/services/06.png";
import IMG4 from "../assets/img/landing/client/services/08.png";
import IMG5 from "../assets/img/landing/client/services/07.png";
import IMG6 from "../assets/img/landing/client/services/12.png";
import IMG7 from "../assets/img/landing/client/services/10.png";
import IMG8 from "../assets/img/landing/client/services/11.png";
import IMG9 from "../assets/img/landing/client/services/09.png";
import IMG10 from "../assets/img/landing/client/services/15.png";
import IMG11 from "../assets/img/landing/client/services/14.png";
import IMG12 from "../assets/img/landing/client/services/03.png";

import IMG_ADMIN_1 from "../assets/img/landing/salon/01.png";
import IMG_ADMIN_2 from "../assets/img/landing/salon/02.png";
import IMG_ADMIN_3 from "../assets/img/landing/salon/03.png";
import IMG_ADMIN_4 from "../assets/img/landing/salon/04.png";
import IMG_ADMIN_5 from "../assets/img/landing/salon/05.png";
import IMG_ADMIN_6 from "../assets/img/landing/salon/06.png";
import IMG_ADMIN_7 from "../assets/img/landing/salon/07.png";
import IMG_ADMIN_8 from "../assets/img/landing/salon/08.png";
import IMG_ADMIN_9 from "../assets/img/landing/salon/09.png";
import IMG_ADMIN_10 from "../assets/img/landing/salon/10.png";
import IMG_ADMIN_11 from "../assets/img/landing/salon/11.png";
import IMG_ADMIN_12 from "../assets/img/landing/salon/12.png";

export const CITIES = {
  Tirane: "Tiranë",
  Berat: "Berat",
  Bulqize: "Bulqizë",
  Delvine: "Delvinë",
  Devoll: "Devoll",
  Diber: "Dibër",
  Durres: "Durrës",
  Elbasan: "Elbasan",
  Fier: "Fier",
  Gjirokaster: "Gjirokastër",
  Gramsh: "Gramsh",
  Has: "Has",
  Kavaje: "Kavajë",
  Kolonje: "Kolonjë",
  Korce: "Korcë",
  Kruje: "Krujë",
  Kucove: "Kuçovë",
  Kukes: "Kukës",
  Kurbin: "Kurbin",
  Lezhe: "Lezhë",
  Librazhd: "Librazhd",
  Lushnje: "Lushnje",
  Malesi_e_Madhe: "Malësi e Madhe",
  Mallakaster: "Mallakastër",
  Mat: "Mat",
  Mirdite: "Mirditë",
  Peqin: "Peqin",
  Permet: "Përmet",
  Pogradec: "Pogradec",
  Puke: "Pukë",
  Sarande: "Sarandë",
  Shkoder: "Shkodër",
  Skrapar: "Skrapar",
  Tepelene: "Tepelenë",
  Tropoje: "Tropojë",
  Vlore: "Vlorë",
};

export const SUBSCRIPTION_PLANS = {
  TRIAL: {
    enum: "TRIAL",
  },
  BASIC: {
    enum: "BASIC",
  },
  SILVER: {
    enum: "SILVER",
  },
  GOLD: {
    enum: "GOLD",
  },
};

export const LANDING_WHY_SERVICES = [
  {
    name: "Prerje floku",
    img: IMG1,
    color: "#e3b778",
  },
  {
    name: "Make Up",
    img: IMG2,
    color: "#e6c27e",
  },
  {
    name: "Qerpikë dhe vetulla",
    img: IMG3,
    color: "#dcd7a0",
  },
  {
    name: "Thonj",
    img: IMG4,
    color: "#fae076",
  },
  {
    name: "Mjekra",
    img: IMG5,
    color: "#c8bc8e",
  },
  {
    name: "Trajtim trupi",
    img: IMG6,
    color: "#ede8ba",
  },
  {
    name: "Trajtim fytyre",
    img: IMG7,
    color: "#dfc98a",
  },
  {
    name: "Trajtim floku",
    img: IMG8,
    color: "#c8a951",
  },
  {
    name: "Depilim",
    img: IMG9,
    color: "#f3eaaf",
  },
  {
    name: "Produkte kozmetike",
    img: IMG10,

    color: "#fdea92",
  },
  {
    name: "Spa",
    img: IMG11,
    color: "#d9b863",
  },
  {
    name: "Lyerje Floku",
    img: IMG12,
    color: "#e6daac",
  },
];

export const LANDING_WHY_BUSINESS = [
  {
    name: "Parukeri",
    img: IMG_ADMIN_1,
    color: "#e3b778",
  },
  {
    name: "Salon Bukurie",
    img: IMG_ADMIN_2,
    color: "#e6c27e",
  },
  {
    name: "Salon për thonjtë",
    img: IMG_ADMIN_3,
    color: "#dcd7a0",
  },
  {
    name: "Artist Make-Up",
    img: IMG_ADMIN_4,
    color: "#fae076",
  },
  {
    name: "BarberShop",
    img: IMG_ADMIN_5,
    color: "#c8bc8e",
  },
  {
    name: "Qendër dermatologjike",
    img: IMG_ADMIN_6,
    color: "#ede8ba",
  },
  {
    name: "Qendër për masazhe",
    img: IMG_ADMIN_7,
    color: "#dfc98a",
  },
  {
    name: "Qendër estetike",
    img: IMG_ADMIN_8,
    color: "#c8a951",
  },
  {
    name: "Qendër Depilimi",
    img: IMG_ADMIN_9,
    color: "#f3eaaf",
  },
  {
    name: "Vetulla e Qerpikë",
    img: IMG_ADMIN_10,
    color: "#fdea92",
  },
  {
    name: "SPA",
    img: IMG_ADMIN_11,
    color: "#f3eaaf",
  },
  {
    name: "Wellness Center",
    img: IMG_ADMIN_12,
    color: "#fdea92",
  },
];

export const APP_URLS = {
  ios: {
    client: "https://apps.apple.com/al/app/kerativ/id1561923546",
    admin: "https://apps.apple.com/al/app/kerativ-admin/id1563891363",
  },
  android: {
    client: "https://play.google.com/store/apps/details?id=com.kerativ",
    admin: "https://play.google.com/store/apps/details?id=com.kerativ.admin",
  },
};

export const NAV_LINKS_CLIENT = [
  {
    title: "Për kë",
    href: "#whatFor",
  },
  {
    title: "Pse Kerativ",
    href: "#why",
  },
  {
    title: "Shërbime",
    href: "#funcionalities",
  },
  {
    title: "Programi besnikërisë",
    href: "#loyalty",
  },
  {
    title: "Shkarko",
    href: "#download",
  },
  {
    title: "Kontakto",
    href: "#contact",
  },
  {
    title: "FAQ",
    href: "#faq",
  },
];

export const NAV_LINKS_SALON = [
  {
    title: "Për kë",
    href: "#whatFor",
  },
  {
    title: "Pse Kerativ",
    href: "#why",
  },
  {
    title: "Shërbime",
    href: "#funcionalities",
  },
  {
    title: "Paketat",
    href: "#packages",
  },
  {
    title: "Shkarko",
    href: "#download",
  },
  {
    title: "Kontakto",
    href: "#contact",
  },
  {
    title: "FAQ",
    href: "#faq",
  },
];

import React from "react";
import { Col, Container, Row } from "@themesberg/react-bootstrap";
import { NAV_LINKS_CLIENT, NAV_LINKS_SALON } from "../../utils/constants";

import LogoLight from "../../assets/img/landing/client/logo-light.png";
import fbIcon from "../../assets/img/landing/client/fb-icon.png";
import instaIcon from "../../assets/img/landing/client/insta-icon.png";

import DownloadButtons from "./DownloadButtons";

const Footer = ({ pageType }) => {
  const links = pageType === "client" ? NAV_LINKS_CLIENT : NAV_LINKS_SALON;

  return (
    <section className="py-4 bg-dark footer" id="footer">
      <Container>
        <Row>
          <Col xs={12} lg={4} className="">
            <img
              src={LogoLight}
              style={{ maxWidth: "60%" }}
              alt="Kerativ yellow logo"
            />
            <p className="ff-medium">
              Aplikacioni i vetëm mobile në Shpipëri, i cili shërben për
              rezervime online, menaxhim biznesi dhe pagesë në përfundim të
              shërbimit, për çdo Sallon Bukurie dhe Spa
            </p>
            <ul className="mt-4">
              <li>
                <a
                  href="https://www.facebook.com/Kerativ-107302211566475"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fbIcon} alt="facebook icon" />
                </a>
              </li>
              <li className="ms-4">
                <a
                  href="https://www.instagram.com/kerativ.al/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instaIcon} alt="instagram icon" />
                </a>
              </li>
              {/* <li className="ms-4">
                <img src={linkedinIcon} alt="linkedin icon" />
              </li> */}
            </ul>
          </Col>
          <Col
            xs={12}
            lg={5}
            className="footer-list d-flex justify-content-lg-center mb-4 mb-lg-0"
          >
            <ul className="mt-4">
              {links.map((link) => {
                return (
                  <li key={link.href}>
                    <a href={link.href}>{link.title}</a>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col xs={12} lg={3} className="footer-buttons">
            <p className="ff-bold ">Shkarkoje tani në: </p>
            <DownloadButtons classes="flex-column mt-4" />
          </Col>
        </Row>
        <p className="powered_by">
          Powered by <a href="https://techforgers.com">TechForgers</a>
        </p>
      </Container>
    </section>
  );
};

export default Footer;

import { Col, Container, Image, Row } from "@themesberg/react-bootstrap";
import React from "react";
import { LANDING_WHY_SERVICES } from "../../../utils/constants";

import onboarding01 from "../../../assets/img/landing/client/onboarding01.png";
import ServiceCard from "../ServiceCard";

const whatFor = () => {
  return (
    <section className=" mt-5 pb-4 overflow-hidden whatFor">
      <Container>
        <Row className="justify-content-between">
          <Col lg={6} className="order-lg-2 mb-2">
            <h3 className="h1-lg text-lg-right ff-black">
              Po kërkon një LOOK të ri?
            </h3>
            <div className="ff-regular text-lg-right fs-lg-24">
              Këtu gjendesh në vendin ideal me fokus
            </div>
            <p className="mb-3 ff-regular text-lg-right fs-lg-24">
              PËRKUJDESJEN ndaj Vetes
            </p>
          </Col>
          <Col lg={6} className="order-lg-1">
            <div>
              <Image src={onboarding01} alt="Women in the mirror" />
            </div>
          </Col>
        </Row>
        <div id="whatFor">
          <Row className="pt-6 mnt-3">
            <Col lg={12} className="mb-2">
              <p className="ff-bold fs-lg-24 text-center">
                Nëse po kërkon një nga shërbimet e mëposhtme atëherë je në
                vendin e duhur
              </p>
            </Col>
            {LANDING_WHY_SERVICES.map((el) => (
              <ServiceCard key={el.name} title={el.name} imgSrc={el.img} />
            ))}
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default whatFor;

import React from "react";
import { Accordion, Col, Container, Row } from "@themesberg/react-bootstrap";
// import AccordionComponent from "../../AccordionComponent";

const FAQ = [
  {
    key: "panel-1",
    title: "+ A është Kerativ falas për këdo?",
    text:
      "Kerativ është falas për të gjithë klientët të cilët duan të rezervojnë një takim tek salloni i tyre i bukurisë, ndërsa për të gjithë bizneset si: Sallone bukurie, Parukeri, Barber Shop, Qendra estetike apo Spa & Wellness Center ka një tarifë fikse mujore në bazë të paketës së perzgjedhur.",
  },
  {
    key: "panel-2",
    title: "+ A më duhet të paguaj online për rezervimin tim?",
    text:
      "Çdo klient, përmes Kerativ, mund të rezervojë takimin online, 24/7, dhe pagesën e kryen në sallon pasi të marrë shërbimin, paketën apo produktin e përzgjedhur.",
  },
  {
    key: "panel-3",
    title: "+ Si funksionon pagesa?",
    text:
      "Kerativ ofron një periudhë falas 2 mujore për këdo, i cili regjistrohet për herë të parë në aplikacion. Pastaj, në varësi të numrit të anëtarëve të stafit të punës që keni, mund të zgjidhni një nga paketat Standart, Plus apo Premium.",
  },
  {
    key: "panel-4",
    title: "+ Çfarë duhet të bëj nëse dua të shtoj më shumë staf?",
    text:
      "Në varësi të paketës së zgjedhur, ju mund të shtoni lehtësisht anëtarët e stafit. Nëse ju doni të rrisni numrin e anëtarëve te stafit dhe doni të kaloni në një paketë tjetër atëherë na kontaktoni.",
  },
  {
    key: "panel-5",
    title: "+ A ka kosto të fshehura në Kerativ?",
    text:
      "Jo. Ajo që shihni është ajo që merrni. Kerativ ofron të gjitha funksionalitetet sipas paketës së përzgjedhur. Nuk ka dhe nuk do ketë asnjëherë, asnjë tarifë shtesë për numër prenotimesh apo numër shërbimesh. Ju mund të përfitoni rezervime të palimituara brenda paketës së përzgjedhur nga ju.",
  },
  {
    key: "panel-6",
    title: "+ A mund të bëj abonime 1 vjeçare?",
    text:
      "Përveç paketave mujore Kerativ ofron dhe plane 1 vjeçare me çmime tepër të arsyeshme. Domethënë, nesë ti regjistrohesh sot me abonim 1 vjeçar, ti do paguash vetëm 10 muaj dhe 2 muaj të tjerë do i përfitosh falas. Kjo nuk përfshin 2 muajt e parë falas kur regjistrohesh për herë të parë në Kerativ.",
  },
  {
    key: "panel-7",
    title: "+ Si faturohem nëse regjistrohem në mes të muajit?",
    text:
      "Kur ju zgjidhni të regjistroni llogarinë tuaj, një cikël faturimi zgjidhet automatikisht për ju. Pra nëse ju zgjidhni të regjistroheni në datën 15 të muajit, abonimi juaj do përfundojë në datën 14 të muajin pasardhës.",
  },
  {
    key: "panel-8",
    title: "+ Sa i sigurtë është Kerativ?",
    text:
      "Mbrojtja e të dhënave, për Kerativ është fokusi ynë primar. Ne përdorim masa mbrojtëse strikte për të ruajtur integritetin dhe sigurinë e informacionit tuaj, duke mos i ndarë kurrë këto informacione me palë te treta. Për më tepër, të gjitha të dhënat e përdoruesit rezervohen në mënyrë të sigurt, përmes sigurisë SSL dhe pajisjeve, të cilat zbatojnë firewalls dhe kufizime të një sigurie të lartë.",
  },
];

const Faq = () => {
  return (
    <section
      className="pt-6 mnt-5 pb-3 overflow-hidden bg-light faq-section"
      id="faq"
    >
      <Container>
        <Row className="justify-content-center">
          <h3 className="h1-lg ff-black mb-4 text-center">FAQ</h3>
          <h3 className="h1-lg ff-black mb-4 text-center">
            Pyetjet më të shpeshta
          </h3>
          <Col xs={12} lg={6} className="mb-4 mb-lg-0">
            <Accordion defaultActiveKey="">
              {FAQ.map((el, index, array) => (
                <Accordion.Item key={el.key} eventKey={el.key}>
                  <Accordion.Button
                    variant="link"
                    className="w-100 d-flex justify-content-between"
                  >
                    <span className="fs-lg-24 mb-0 ff-bold">{el.title}</span>
                  </Accordion.Button>
                  <Accordion.Body>
                    <p>{el.text}</p>
                  </Accordion.Body>
                  {index !== array.length - 1 && (
                    <div className="landing-border" />
                  )}
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Faq;

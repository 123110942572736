import { Col } from "@themesberg/react-bootstrap";
import React from "react";

const ServiceCard = ({ imgSrc, title }) => {
  return (
    <Col xs={2} lg={1} className="mb-lg-0 ps-0 mt-1">
      <div className="">
        <img src={imgSrc} alt="service placeholder" />
      </div>
      <div className="service-title ff-medium mt-1 text-center">{title}</div>
    </Col>
  );
};

export default ServiceCard;

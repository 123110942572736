import React from "react";
import { faAppStore, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { useRouteMatch } from "react-router";
import { Button } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_URLS } from "../../utils/constants";

const DownloadButtons = ({ classes }) => {
	const match = useRouteMatch("/biznes");
	const type = !!match ? "admin" : "client";
	return (
		<div className={`d-flex ${classes} mt-2`}>
			<Button
				target="_blank"
				href={APP_URLS.android[type]}
				variant="light"
				className="landing-button landing-shadow"
			>
				<FontAwesomeIcon icon={faGooglePlay} className="me-2" /> Play Store
			</Button>
			<Button
				target="_blank"
				className="landing-button landing-shadow apple-btn ms-3"
				href={APP_URLS.ios[type]}
				variant="light"
			>
				<FontAwesomeIcon icon={faAppStore} className="me-2" /> App Store
			</Button>
		</div>
	);
};

export default DownloadButtons;

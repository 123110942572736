import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// core styles
import "./scss/kerativ.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
// import "react-datetime/css/react-datetime.css";

// import { Provider } from "react-redux";
// import configureStore from "./redux/configureStore";

import HomePage from "./pages/HomePage";
// import ScrollToTop from "./components/ScrollToTop";

// const store = configureStore();

ReactDOM.render(
  <BrowserRouter>
    {/* <Provider store={store}> */}
    <HomePage />
    {/* </Provider> */}
  </BrowserRouter>,
  document.getElementById("root")
);

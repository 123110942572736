import React from "react";
import { Switch, Route } from "react-router-dom";
import { Routes } from "../routes";

import LandingClient from "./LandingClient";
import LandingSalon from "./LandingSalon";

const HomePage = () => {
  return (
    <Switch>
      <Route
        exact
        path={Routes.PresentationClient.path}
        component={LandingClient}
      />
      <Route
        exact
        path={Routes.PresentationSalon.path}
        component={LandingSalon}
      />
    </Switch>
  );
};

export default HomePage;

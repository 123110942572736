import aboutScreenshotClient from "../assets/img/landing/client/about.png";
import wave1Client from "../assets/img/landing/client/wave-1.svg";

import calendarAdmin from "../assets/img/landing/salon/calendar.png";
import wave1Admin from "../assets/img/landing/salon/wave-1.svg";

export const SECTIONS = {
  client: {
    about_title: "Njihu me aplikacionin nr.1 për Sallone bukurie dhe Spa",
    about_p1:
      "Kerativ është një aplikacion mobile, i cili shërben për rezervime online, dhe pagesë në përfundim të shërbimit.",
    about_p2:
      "Zbulo të gjitha karakteristikat që e bëjnë Kerativ aplikacionin e duhur dhe më të dashurin për rezervime online të takimeve në çdo sallon bukurie, spa apo qendër estetike.",
    screenshot: aboutScreenshotClient,
    wave: wave1Client,
  },
  admin: {
    about_title: "Njihu me aplikacionin nr.1 për Sallone bukurie dhe Spa",
    about_p1:
      "Kerativ është një aplikacion mobile, i cili shërben për rezervime online, dhe pagesë në përfundim të shërbimit.",
    about_p2:
      "Zbulo të gjitha karakteristikat që e bëjnë Kerativ aplikacionin e duhur dhe më të dashurin për rezervime online të takimeve në çdo sallon bukurie, spa apo qendër estetike.",
    screenshot: calendarAdmin,
    wave: wave1Admin,
  },
};

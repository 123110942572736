import React from "react";
import { Col, Row, Container } from "@themesberg/react-bootstrap";
import { ReactComponent as WaveYellow } from "../../assets/img/landing/client/wave-1.svg";
import { ReactComponent as WaveDark } from "../../assets/img/landing/salon/wave-1.svg";
import { SECTIONS } from "../../utils/sectionsData";
import DownloadButtons from "./DownloadButtons";

const AboutSection = ({ pageType }) => {
	const textColor = pageType === "client" ? "text-secondary" : "text-white";
	const bgColor = pageType === "client" ? "bg-primary" : "bg-secondary";
	const Wave = pageType === "client" ? WaveYellow : WaveDark;
	return (
		<section
			className={`about pt-6 mnt-5  overflow-hidden position-relative ${bgColor}`}
			id="about"
		>
			<Wave className="wave-svg position-absolute" />
			<Container className="">
				<Row>
					<Col xs={12} md={6} className="mb-4">
						<div className="z-1 about-content position-relative pt-0 pt-xl-6 pt-xxl-6  mb-5 mb-lg-0">
							<h2 className={`h1-xxl ff-black ${textColor}`}>
								{SECTIONS[pageType].about_title}
							</h2>
							<div className="mb-4 mt-4">
								<p className={`ff-medium ${textColor}`}>
									{SECTIONS[pageType].about_p1}
								</p>
								<div className={`ff-medium ${textColor}`}>
									{SECTIONS[pageType].about_p2}
								</div>
							</div>
							<div className={`ff-black pb-3 pt-2 ${textColor}`}>
								Shkarkoje tani në:
							</div>
							<DownloadButtons classes="mxw-md-440" />
						</div>
					</Col>
					<Col xs={12} md={6} className="">
						<div className="about-image position-relative pb-4">
							<img
								className="about-screenshot"
								src={SECTIONS[pageType].screenshot}
								alt="about screenshot"
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default AboutSection;

import { Col, Container, Row } from "@themesberg/react-bootstrap";
import React from "react";

import onboarding03 from "../../../assets/img/landing/client/onboarding03.png";
import calendar from "../../../assets/img/landing/client/calendar-screenshot.png";

const WhySection = () => {
  return (
    <section className="pt-6 mnt-5 pb-4 bg-light why" id="why">
      <Container className="">
        <h2 className="h1-lg ff-black mb-4">
          Rezervo online lehtë dhe shpejtë
        </h2>
        <p className="ff-regular mb-4 fs-lg-24">
          Rezervo takimin tënd online 24/7, vetëm me një klikim.
        </p>
        <Row>
          <Col xs={12} lg={6} className="mb-5 mb-lg-0">
            <div className="onboarding-image">
              <img
                src={onboarding03}
                alt="Specialist working with client's hair"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="position-relative rotated-image-wrapper">
              <img
                className="rotated-image"
                src={calendar}
                alt="Kerativ screenshot with calendar"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhySection;

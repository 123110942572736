import { Col, Container, Row } from "@themesberg/react-bootstrap";
import React from "react";
import DownloadButtons from "../DownloadButtons";

import raportScreenshot from "../../../assets/img/landing/salon/raport-screenshot.png";
import { ReactComponent as WaveDark } from "../../../assets/img/landing/salon/wave-2.svg";

const RaportSection = () => {
  return (
    <section
      className="overflow-hidden position-relative bg-light raport-section"
      id="raportSection"
    >
      <WaveDark className="position-absolute wave-2" />
      <Container className="z-1 pt-4">
        <Row>
          <h2 className="fs-24 h1-lg ff-black mb-4">
            Shkarkoje aplikacionin tani
          </h2>
          <Col xs={12} lg={6} className="mb-5 mb-lg-0">
            <DownloadButtons classes="mxw-md-440" />
            <h2 className="ff-black mt-3  mt-lg-5">
              dhe përfito nga shërbimet profesionale të Kerativ
            </h2>
            <p className="ff-regular fs-lg-24">
              menaxho rezervimet dhe shiko përmbledhjet javore e mujore vetëm me
              një hap
            </p>
          </Col>
          <Col xs={12} lg={6}>
            <div className="pb-4 d-flex image-wrapper">
              <img
                src={raportScreenshot}
                alt="Specialist working with client's hair"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RaportSection;

import { Container } from "@themesberg/react-bootstrap";
import React from "react";
import DownloadButtons from "./DownloadButtons";

const DownloadNow = ({ pageType }) => {
  const bgColor = pageType === "client" ? "bg-primary" : "bg-secondary";
  const textColor = pageType === "client" ? "text-secondary" : "text-white";

  return (
    <section className={`${bgColor} pb-6  `} id="download">
      <Container className="pt-6 ">
        <div className="text-center">
          <h1 className={`fs-24 h1-lg ff-black mb-4 ${textColor}`}>
            Shkarkoje aplikacionin tani
          </h1>
          <DownloadButtons classes="center-x mxw-md-440" />
        </div>
      </Container>
    </section>
  );
};

export default DownloadNow;

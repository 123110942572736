import React, { useEffect } from "react";
import AboutSection from "../components/LandingPage/AboutSection";
import DownloadNow from "../components/LandingPage/DownloadNow";
import Faq from "../components/LandingPage/Faq";
// import Features from "../components/LandingPage/Client/Features";
// import FourthSection from "../components/LandingPage/Client/FourthSection";
// import LoyaltySection from "../components/LandingPage/Client/LoyaltySection";
import RaportSection from "../components/LandingPage/Salon/RaportSection";
import WhatForSection from "../components/LandingPage/Salon/WhatForSection";
import RippleSection from "../components/LandingPage/Salon/RippleSection";
import ContactUs from "../components/LandingPage/ContactUs";
import Footer from "../components/LandingPage/Footer";
import Features1 from "../components/LandingPage/Salon/Features1";
import Features2 from "../components/LandingPage/Salon/Features2";
import Packages from "../components/LandingPage/Salon/Packages";
import NavbarSalon from "../components/LandingPage/Salon/NavbarSalon";

const LandingSalon = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div className="landing landing-salon">
      <NavbarSalon />
      <AboutSection pageType="admin" />
      <WhatForSection />
      <RippleSection />
      <RaportSection />
      <Features1 />
      <Features2 />
      <Packages />
      <DownloadNow pageType="admin" />
      <ContactUs />
      <Faq />
      {/* 
			<FourthSection />
			<Features />
			<LoyaltySection />
    */}
      <Footer pageType="admin" />
    </div>
  );
};

export default LandingSalon;

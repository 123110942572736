import { Col, Container, Row, Toast } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { axiosApi } from "../../config/axiosConfig";

import phone from "../../assets/img/landing/phone.png";
import sms from "../../assets/img/landing/sms.png";

const validation = {
  name: 50,
  contact: 20,
  message: 2048,
};

const ContactUs = () => {
  let timeout;
  const initialData = {
    name: "",
    contact: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [toastMsg, setToastMsg] = useState(null);

  const showToast = (msg) => setToastMsg(msg);
  const closeToast = () => {
    setToastMsg(null);
    !!timeout && clearTimeout(timeout);
  };

  const disabledButton =
    loading ||
    formData.name.length < 3 ||
    formData.contact.length < 9 ||
    formData.message.length < 10;

  const handleInput = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    if (
      value.length > validation[field] ||
      (field === "contact" && isNaN(Number(value)))
    )
      return;
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (disabledButton) return;
    // console.log(`formData`, formData);
    setLoading(true);
    try {
      await axiosApi.post("/search/support/landing", formData);
      // console.log(`response send email: `, response);
    } catch (error) {
      // console.log(`error send email`, error);
    } finally {
      showToast("Success");
      timeout = setTimeout(() => closeToast(null), 1500);
      setLoading(false);
      setFormData(initialData);
    }
  };

  return (
    <section
      className="py-6 bg-light contact-section position-relative"
      id="contact"
    >
      <Toast
        style={{ top: 0 }}
        show={!!toastMsg}
        onClose={closeToast}
        className="my-3 d-flex justify-content-center center-horizontal-absolute position-absolute"
      >
        <Toast.Body>Faleminderit që na kontaktuat!</Toast.Body>
      </Toast>
      <Container className="">
        <Row>
          <Col xs={12} md={6} className="mb-4 mb-lg-0">
            <h2 className="h1-lg ff-black mb-4">Na kontakto</h2>
            <p className="ff-regular mb-4 fs-lg-24">
              Në çdo moment, ne jemi të gatshëm dhe të disponueshëm për
              bashkëpunim, prandaj na kontakto më poshtë:
            </p>
            <div className="mt-4">
              <div className="d-flex align-items-center">
                <div style={{ maxWidth: "65px" }}>
                  <img src={sms} alt="message icon" />
                </div>
                <p className="ff-regular mb-4 fs-lg-24">
                  Email: info@kerativ.al
                </p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <Row className="justify-content-center">
              <Col xs={12} xl={6}>
                <form action="#">
                  <div className="form-row mb-2 mt-4 mt-lg-0">
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control landing-input mb-4"
                        placeholder="Emri"
                        name="name"
                        value={formData.name}
                        onChange={handleInput}
                        aria-label="contact-form"
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control landing-input mb-4"
                        placeholder="06xxxxxxxx"
                        name="contact"
                        aria-label="contact-form"
                        value={formData.contact}
                        onChange={handleInput}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        rows={5}
                        className="form-control landing-input mb-4"
                        placeholder="..."
                        name="message"
                        value={formData.message}
                        onChange={handleInput}
                        aria-label="contact-form"
                        required
                      />
                    </div>
                    <div className="col-12">
                      <button
                        type="submit"
                        // className="btn btn-primary text-dark shadow-soft btn-block"
                        className="btn landing-button btn-primary text-light landing-shadow btn-block"
                        // data-loading-text="Sending"
                        disabled={disabledButton}
                        onClick={sendMessage}
                      >
                        <span>Dërgo</span>
                      </button>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactUs;

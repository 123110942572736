import { Card, Col, Container, Image, Row } from "@themesberg/react-bootstrap";
import React from "react";

import why1 from "../../../assets/img/landing/salon/why-1.png";
import why2 from "../../../assets/img/landing/salon/why-2.png";
import why3 from "../../../assets/img/landing/salon/why-3.png";
import why4 from "../../../assets/img/landing/salon/why-4.png";

import { LANDING_WHY_BUSINESS } from "../../../utils/constants";
import ServiceCard from "../ServiceCard";

const WhySection = () => {
	return (
		<section className="pb-4 pt-6 mnt-5 overflow-hidden whatFor" id="whatFor">
			<Container className="pt-4">
				<h1 className="ff-bold text-center">Çfarë na bën ne të veçantë?</h1>
				<p className="ff-regular text-center h6 description-1">
					Zbulo të gjitha karakteristikat që e bëjnë Kerativ aplikacionin e
					duhur dhe më të dashurin për rezervime online të takimeve në çdo
					sallon bukurie, spa apo qendër estetike.
				</p>

				<Row className="mt-3">
					<Col xs={12} sm={6} lg={3} className="mb-lg-0">
						<Card className="custom-card-bg mb-4">
							<div className="text-center pb-2">
								<div className="card-icon">
									<Image src={why1} />
								</div>
								<div className="d-flex justify-content-center">
									<p className="card-text ff-medium">
										Rrit pritshmëritë e klientit tënd
									</p>
								</div>
							</div>
						</Card>
					</Col>
					<Col xs={12} sm={6} lg={3} className="mb-lg-0">
						<Card className="custom-card-bg mb-4">
							<div className="text-center pb-2">
								<div className="card-icon">
									<Image src={why2} />
								</div>
								<div className="d-flex justify-content-center">
									<p className="card-text ff-medium text-center">
										Kurse orë në administrim
									</p>
								</div>
							</div>
						</Card>
					</Col>
					<Col xs={12} sm={6} lg={3} className="mb-lg-0">
						<Card className="custom-card-bg mb-4">
							<div className="text-center pb-2">
								<div className="card-icon">
									<Image src={why3} />
								</div>
								<div className="d-flex justify-content-center">
									<p className="card-text ff-medium  text-center">
										Krijo një brand më të dashur
									</p>
								</div>
							</div>
						</Card>
					</Col>
					<Col xs={12} sm={6} lg={3} className="mb-lg-0">
						<Card className="custom-card-bg mb-4">
							<div className="text-center pb-2">
								<div className="card-icon">
									<Image src={why4} />
								</div>
								<div className="d-flex justify-content-center">
									<p className="card-text ff-medium  text-center">
										Rrit të ardhurat e tua
									</p>
								</div>
							</div>
						</Card>
					</Col>
				</Row>
				<div>
					<Row className="justify-content-between mb-2">
						<Col lg={12} className="mb-2">
							<h1 className="ff-bold text-center">Për kë është Kerativ?</h1>
							<p className="ff-bold fs-lg-24 text-center">
								Nëse ti e gjen veten në një nga bizneset e mëposhtme, atëherë
								Kerativ është për ty.
							</p>
						</Col>
						{LANDING_WHY_BUSINESS.map((el) => (
							<ServiceCard
								key={el.name}
								bg={el.color}
								title={el.name}
								imgSrc={el.img}
							/>
						))}
					</Row>
				</div>
			</Container>
		</section>
	);
};

export default WhySection;

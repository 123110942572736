import { Col, Container, Row } from "@themesberg/react-bootstrap";
import React from "react";
import onboarding04 from "../../../assets/img/landing/client/onboarding04.png";
import loyaltyScreenshot from "../../../assets/img/landing/client/loyalty-screenshot.png";

const LoyaltySection = () => {
  return (
    <section className="pt-6 pb-4 bg-light loyalty-section" id="loyalty">
      <Container className="">
        <Row>
          <Col xs={12} lg={7} className="mb-5 mb-lg-0">
            <h2 className="h1-lg ff-black mb-4">Përfito dhurata dhe shpërblime</h2>
            <p className="ff-regular mb-4 fs-lg-24">
              Mblidh pikë dhe shpërblehu nga programi i besnikërisë.
            </p>
            <div className="onboarding-image">
              <img src={onboarding04} alt="Specialist working with client's hair" />
            </div>
          </Col>
          <Col xs={12} lg={5} className="position-relative ">
            <div className="text-center screenshot-container">
              <img
                className="loyalty-screenshot"
                src={loyaltyScreenshot}
                alt="Kerativ screenshot loyalty program"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LoyaltySection;

import React, { useEffect } from "react";
import AboutSection from "../components/LandingPage/AboutSection";
import DownloadNow from "../components/LandingPage/DownloadNow";
import Faq from "../components/LandingPage/Faq";
import Features from "../components/LandingPage/Client/Features";
import WhySection from "../components/LandingPage/Client/WhySection";
import LoyaltySection from "../components/LandingPage/Client/LoyaltySection";
import ThirdSection from "../components/LandingPage/Client/ThirdSection";
import WhatForSection from "../components/LandingPage/Client/WhatForSection";
import ContactUs from "../components/LandingPage/ContactUs";
import Footer from "../components/LandingPage/Footer";
import NavbarClient from "../components/LandingPage/Client/NavbarClient";

const LandingClient = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div className="landing landing-client">
      {/* <NavbarLanding variant="light" /> */}
      <NavbarClient />
      <AboutSection pageType="client" />
      <WhatForSection />
      <ThirdSection />
      <WhySection />
      <Features />
      <LoyaltySection />
      <DownloadNow pageType="client" />
      <ContactUs />
      <Faq />
      <Footer pageType="client" />
    </div>
  );
};

export default LandingClient;

import React from "react";
import { Col, Container, Row, Image } from "@themesberg/react-bootstrap";
import twoImg from "../../../assets/img/landing/salon/two-img.png";

const Features2 = () => {
  return (
    <section className="bg-light features-admin-section" id="">
      <Container className="pt-5 pb-5">
        <Row>
          <Col
            xs={12}
            lg={4}
            className="offset-lg-1 d-flex flex-column justify-content-center"
          >
            <div className="mb-5">
              <div className="ff-bold fs-36">04.</div>
              <div className="ff-bold fs-26">Marketing</div>
              <div className="ff-regular">
                Rrite brandin dhe besueshmërinë, për të fituar më shumë klientë
                besnikë, me anë të mjeteve tona të marketingut që ndihmojnë në
                rritjen e numrit të klientëve.
              </div>
            </div>
            <div className="mb-5">
              <div className="ff-bold fs-36">05.</div>
              <div className="ff-bold fs-26">Reklamim i produkteve</div>
              <div className="ff-regular">
                Reklamo të gjithë produktet e tua kozmetike apo dermatologjike
                dhe bëje më të lehtë zgjedhjen për klientët e tu. Dërgoja
                produktin klientit në shtëpi dhe klienti mund të paguajë në
                dorëzim.
              </div>
            </div>
            <div className="mb-5">
              <div className="ff-bold fs-36">06.</div>
              <div className="ff-bold fs-26">Raporte të të ardhurave</div>
              <div className="ff-regular">
                Kontrollo në mënyrë të detajuar performancën e biznesit tënd
                nëpërmjet raporteve të të ardhurave ditore apo mujore, të cilat
                përfshijnë, që nga totali i të ardhurave deri tek numri
                rezervimeve të anuluara të klientëve.
              </div>
            </div>
          </Col>
          <Col xs={12} lg={7} className="">
            <div className="">
              <Image src={twoImg} className="" />
            </div>
          </Col>
          {/* <Col xs={12} lg={7} className="images-column">
            <div className="features2-image-wrapper">
              <Image src={adminProfile} className="left" />
              <Image src={adminCalendar} className="right" />
            </div>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
};

export default Features2;

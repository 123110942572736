import React from "react";
import { Col, Container, Row } from "@themesberg/react-bootstrap";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1025: { items: 3 },
};

const items = [
  <div className="package">
    <h1 className="ff-bold fs-38 text-center ">Standart</h1>
    <ul>
      <li className="ff-light">
        Menaxhim dhe kontroll i plotë mbi rezervimevet e tua
      </li>
      <li className="ff-light">Rezervime të palimituara</li>
      <li className="ff-light">Produkte, shërbime dhe paketa të palimituara</li>
      <li className="ff-light">Vlerësime (review) të palimituara</li>
      <li className="ff-light">Program besnikërie </li>
      <li className="ff-light">
        Njoftime të dedikuara për klientët besnik, për çdo shërbim të ri
      </li>
      <li className="ff-light">QR Code i personalizuar me logo</li>
      <li className="ff-light">
        <strong>1 specialist</strong>
      </li>
    </ul>
    <div className="contact-button fs-24 ff-bold text-white">
      <a href="/biznes#contact">Na kontakto</a>
    </div>
  </div>,
  <div className="package">
    <h1 className="ff-bold fs-38 text-center ">Plus</h1>
    <ul>
      <li className="ff-light">
        Menaxhim dhe kontroll i plotë mbi rezervimevet e tua
      </li>
      <li className="ff-light">Rezervime të palimituara</li>
      <li className="ff-light">Produkte, shërbime dhe paketa të palimituara</li>
      <li className="ff-light">Vlerësime (review) të palimituara</li>
      <li className="ff-light">Program besnikërie </li>
      <li className="ff-light">
        Njoftime të dedikuara për klientët besnik, për çdo shërbim të ri
      </li>
      <li className="ff-light">QR Code i personalizuar me logo</li>
      <li className="ff-light">
        <strong>Raporte të performancës së biznesit</strong>
      </li>
      <li className="ff-light">
        <strong>Raporte të performancës së stafit</strong>
      </li>
      <li className="ff-light">
        <strong>2-7 specialistë</strong>
      </li>
    </ul>
    <div className="contact-button fs-24 ff-bold text-white">
      <a href="/biznes#contact">Na kontakto</a>
    </div>
    {/* <div className=" d-flex">
      <Button
        className="landing-button landing-shadow text-white"
        variant="primary"
      >
        Na kontakto
      </Button>
    </div> */}
  </div>,
  <div className="package">
    <h1 className="ff-bold fs-38 text-center ">Premium</h1>
    <ul>
      <li className="ff-light">
        Menaxhim dhe kontroll i plotë mbi rezervimevet e tua
      </li>
      <li className="ff-light">Rezervime të palimituara</li>
      <li className="ff-light">Produkte, shërbime dhe paketa të palimituara</li>
      <li className="ff-light">Vlerësime (review) të palimituara</li>
      <li className="ff-light">Program besnikërie </li>
      <li className="ff-light">
        Njoftime të dedikuara për klientët besnik, për çdo shërbim të ri
      </li>
      <li className="ff-light">QR Code i personalizuar me logo</li>
      <li className="ff-light">
        <strong>Raporte të performancës së biznesit</strong>
      </li>
      <li className="ff-light">
        <strong>Raporte të performancës së stafit</strong>
      </li>
      <li className="ff-light">
        <strong>deri në 50 specialistë</strong>
      </li>
    </ul>
    <div className="contact-button fs-24 ff-bold text-white">
      <a href="/biznes#contact">Na kontakto</a>
    </div>
  </div>,
];

const Packages = () => {
  return (
    <section className="bg-secondary packages" id="packages">
      <Container className="pt-5 pb-5">
        <Row className="mb-5">
          <h1 className="ff-bold text-center text-white">Paketat tona</h1>
          <p className="ff-regular fs-lg-24 text-center text-white">
            Zgjidh një nga paketat tona të mrekullueshme me karakteristika që i
            përshtaten bizneseve të çdo madhësie, në çdo fazë të rrugëtimit të
            biznesit.
          </p>
        </Row>
        <Row>
          <Col md={12}>
            {/* <Col className="offset-md-1" md={10}> */}
            <AliceCarousel
              mouseTracking
              items={items}
              responsive={responsive}
              controlsStrategy="alternate"
              disableButtonsControls
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Packages;

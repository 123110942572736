import React from "react";
import { Col, Container, Row, Image } from "@themesberg/react-bootstrap";
import threeImg from "../../../assets/img/landing/salon/three-img.png";

const Features1 = () => {
  return (
    <section
      className="bg-secondary features-admin-section"
      id="funcionalities"
    >
      <Container className="pt-5 pb-5">
        <Row>
          <Col
            xs={12}
            lg={4}
            className="order-lg-2 d-flex flex-column justify-content-center"
          >
            {/* <h2 className="h1-lg ff-black mb-4 text-white">
              Me Kerativ ti mund të:
            </h2> */}
            <div className="mb-5">
              <div className="ff-bold fs-36 text-white">01.</div>
              <div className="ff-bold fs-26 text-white">Rezervime online</div>
              <div className="ff-regular text-white">
                Maksimizo kohën tënde nëpërmjet rezervimit online. Me anë të
                Kerativ, klientët e tu mund ta zbulojnë biznesin tënd në çdo
                kohë dhe të rezervojnë 24/7.
              </div>
            </div>
            <div className="mb-5">
              <div className="ff-bold fs-36 text-white">02.</div>
              <div className="ff-bold fs-26 text-white">
                Menaxhim i klientëve
              </div>
              <div className="ff-regular text-white">
                Qëndro i lidhur gjithë kohën me klientët e tu nëpërmjet Kerativ
                i cili dërgon Lajmërime dhe Kujtesa të automatizuara të
                rezervimeve të kryera tek ti.
              </div>
            </div>
            <div className="mb-5">
              <div className="ff-bold fs-36 text-white">03.</div>
              <div className="ff-bold fs-26 text-white">Menaxhim i stafit</div>
              <div className="ff-regular text-white">
                Shto të gjithë personat e stafit tënd duke caktuar orarët e
                punës e secilit prej tyre në kalendar dhe duke kontrolluar
                kështu orët e lira të gjithësecilit.
              </div>
            </div>
          </Col>
          {/* <Col xs={12} lg={8} className="images-column order-lg-1">
            <div className="features-image-wrapper">
              <Image src={addProduct} className="left" />
              <Image src={addPackage} className="middle" />
              <Image src={addService} className="right" />
            </div>
          </Col> */}
          <Col xs={12} lg={8} className="order-lg-1">
            <div className="">
              <Image src={threeImg} className="" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Features1;

import { Col, Container, Row } from "@themesberg/react-bootstrap";
import React from "react";
import DownloadButtons from "../DownloadButtons";
import onboarding02 from "../../../assets/img/landing/client/onboarding02.png";
import wave2 from "../../../assets/img/landing/client/wave-2.svg";

const ThirdSection = () => {
  return (
    <section
      className="overflow-hidden position-relative pt-5  bg-light third-section"
      id="thirdSection"
    >
      <img className="wave-image" src={wave2} alt="wave-path" />
      <Container className="z-1 third-section-container">
        <Row>
          <h2 className="fs-24 h1-lg ff-black mb-4">Shkarkoje aplikacionin tani</h2>
          <Col xs={12} lg={6} className="mb-5 mb-lg-0">
            <DownloadButtons classes="mxw-md-440" />
            <h2 className="ff-black mt-3  mt-lg-5">dhe gjej stilistin tënd të preferuar</h2>
            <p className="ff-regular fs-lg-24">
              Kërko dhe gjej online një Sallon Bukurie, Berber, SPA apo Qendër Estetike që të
              përshtatet.
            </p>
          </Col>
          <Col xs={12} lg={6}>
            <div className="pb-4 d-flex image-wrapper">
              <img src={onboarding02} alt="Specialist working with client's hair" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ThirdSection;

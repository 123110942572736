import { Col, Container, Row } from "@themesberg/react-bootstrap";
import React from "react";
import doubleImage from "../../../assets/img/landing/client/double-image.png";

const Features = () => {
	return (
		<section className="pb-4 pt-6 mnt-5 features-section" id="funcionalities">
			<div className="pt-4 bg-primary">
				<Container className="">
					<Row>
						<Col xs={12} lg={6}>
							<h2 className="ff-regular mb-4">
								Rezervo të bukurën. Shkarko
								<span className="ff-black"> Kerativ</span> dhe gjej:
							</h2>
						</Col>
					</Row>

					<Row className="mt-lg-4">
						<Col xs={12} lg={6} className="order-lg-2">
							<Row className="mt-xxl-7">
								<Col xs={12} lg={6} className="">
									<div>
										<div className="ff-bold fs-36">01.</div>
										<div className="ff-bold fs-26">Sallonin më të afërt</div>
										<div className="ff-light">
											Zbulo listën e salloneve më të afërta me vendndodhjen
											tënde në hartë.
										</div>
									</div>
								</Col>
								<Col xs={12} lg={6} className="">
									<div>
										<div className="ff-bold fs-36">02.</div>
										<div className="ff-bold fs-26">Shërbime</div>
										<div className="ff-light">
											Rezervo nga lista e shërbimeve që ofron salloni yt i
											preferuar.
										</div>
									</div>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col xs={12} lg={6} className="">
									<div>
										<div className="ff-bold fs-36">03.</div>
										<div className="ff-bold fs-26">Produkte</div>
										<div className="ff-light">
											Shiko produktet kozmetike të çdo salloni dhe çmimet
											përkatëse.
										</div>
									</div>
								</Col>
								<Col xs={12} lg={6} className="">
									<div>
										<div className="ff-bold fs-36">04.</div>
										<div className="ff-bold fs-26">Paketa</div>
										<div className="ff-light">
											Kurse para duke zgjedhur një nga paketat me çmime speciale
										</div>
									</div>
								</Col>
							</Row>
						</Col>
						<Col xs={12} lg={6} className="mt-4 order-lg-1">
							<div className="two-photos">
								<img
									className=""
									src={doubleImage}
									alt="Kerativ app store Services screenshot"
								/>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</section>
	);
};

export default Features;

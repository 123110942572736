import React from "react";
import { Col, Container, Row } from "@themesberg/react-bootstrap";
import splashscreen from "../../../assets/img/landing/salon/splashscreen.png";

const RippleSection = () => {
  return (
    <section
      className="mt-5 overflow-hidden bg-secondary ripple-section why"
      id="why"
    >
      <Container>
        <div className="animation-circle-inverse">
          <i></i>
          <i></i>
          <i></i>
        </div>
        <Row className="reasons">
          <Col md={4}>
            <div className="reasons-box">
              <div className="reasons-timeline">
                <ul className="text-md-right">
                  <li>
                    Rrit biznesin dhe fitimet e tua me anë të rritjes së numrit
                    të klientëve
                  </li>
                  <li>
                    Thuaji lamtumirë fletave të letrave sepse Kerativ i menaxhon
                    rezervimet e tua online
                  </li>
                  <li>
                    Jepi një përvojë të jashtëzakonshme klientëve të tu duke i
                    lejuar ata të rezervojnë 24/7
                  </li>
                  <li>
                    Shpërbleji klientët e tu më besnikë duke përdorur programin
                    tonë të besnikërisë
                  </li>
                  <li>
                    Përkushtohu më shumë ndaj klientëve të tu duke evituar
                    telefonatat e klientëve të gjatë orarit të punës
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col md={4} className="ripple-image">
            <div className="text-center position-relative w-100">
              <img
                className="splash-screenshot"
                src={splashscreen}
                alt="Kerativ screenshot loyalty program"
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="reasons-box">
              <div className="reasons-timeline-right">
                <ul className="text-left">
                  <li>Kurse para duke reduktuar kostot administrative</li>
                  <li>
                    Ndërto një brand të memorizueshëm duke u shfaqur i pari në
                    listën e kërkimeve
                  </li>
                  <li>
                    Qëndro i sigurtë me të dhënat e tua të ruajtura në mënyrë
                    diskrete
                  </li>
                  <li>
                    Akseso dhe menaxho biznesin tënd lehtësisht në çdo pajisje
                    Android apo iOS
                  </li>
                  <li>
                    Kurse kohë për administrimin e biznesit dhe jepi kohë vetes
                    për gjërat që ke dashur gjithmonë të bësh.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RippleSection;
